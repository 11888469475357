<i18n>
{
    "ar": {

        "sections": {
            "1": {
                "title": "شركة تطوير برمجيات وأنظمة متطورة",
                "subtitle": "نعمل على ابتكار الأفكار غير التقليدية؛ التي تحول أفكار العملاء إلى واقع، وتوفر عليهم الوقت والجهد والتكلفة. نمتلك الأدوات العملية؛ التي جعلتنا الخيار الأمثل لعدد من المؤسسات."
            },
            "about": {
                "title": "معلومات عن الشركة",
                "subtitle": "معلومات وتفاصيل عن الشركة وآلية عملها وأهدافها",
                "row1": {
                    "title": "نبذة عن الشركة",
                    "content": "Zamzam متخصصون في الخدمات التقنية وتقديم الحلول البرمجية التي تدعم تطوير الأعمال. نوظف خبرتنا في في تطوير وتصميم برمجيات وأنظمة مبتكرة. نقدم مجموعة من الخدمات المترابطة التي تضم التعليم الإلكتروني وتطوير التكنولوجيا التعليمية وتنفيذ مشاريع تقنية بأفكار إبداعية. نعمل وفق رؤية واضحة ونسعى أن نكون من المساهمين في الاعتماد على التقنية في تطوير مختلف القطاعات؛ ونعمل على أن نكون من المشاركين في التحول الرقمي الذي يشهده العالم في الوقت الحالي، وذلك من خلال تقديم خدماتنا على نطاق واسع داخل وخارج الوطن العربي. Zamzam نصنع المستقبل بأفكارنا."
                },
                "row2": {
                    "title": "تطوير برمجيات وأنظمة متطورة!",
                    "content": "نضع نصب أعيننا مجموعة من الأهداف والمشاريع؛ حيث نركز جهودنا على تطوير وتصميم أنظمة برمجية مبتكرة تعمل على دعم العديد من المجالات. وفي الوقت الحالي؛ فإننا نسخر أفكارنا في القطاع التعليمي بشكل خاص؛ لما له من آثار إيجابية على المدى البعيد والقريب. نضع خبرتنا بين أيديكم؛ لنصل إلى نتيجة مرضية تفوق توقعاتكم"
                }
            },
            "contact": {
                "subtitle": "يسعدنا تلقي أسئلتكم واستفساراتكم من خلال تعبئة النموذج التالي، أو من خلال قنوات التواصل"
            },
            "timeline": {
                "title": "الشريط الزمني",
                "subtitle": "..."
            },
            "products": {
                "title": "مشاريعنا",
                "subtitle": "المنصات والأنظمة البرمجية التي قمنا بتنفيذها"
            },
            "industries": {
                "title": "مجالات عملنا",
                "subtitle": "المجالات والصناعات التي نعمل حالياً على تطوير برمجيات متقدمة لدعمها وحل مشكلاتها",
                "cols": [
                    {
                        "headline": "التعلم اﻹلكتروني",
                        "description": "نعمل على تصميم منصات تطور عملية التعليم الإلكتروني؛ حيث إن الحاجة باتت ملحة للمزيد من الأفكار الفعّالة، إذ يعمل فريق من المطورين على تنفيذ مشروعات برمجية بحرفيةٍ تامة، تخدم هذا القطاع، لأننا نعي جيدًا قيمة التعليم في بناء مجتمعاتٍ رائدة. ندرك أن تطوير التعليم هو المحرك الأول لتطوير الأمم، وهو البداية الحقيقية نحو التغيير"
                    },
                    {
                        "headline": "التكنولوجيا التعليمية",
                        "description": "نعمل على ابتكار بيئة تعلم تفاعلية؛ تجمع بين التكنولوجيا والأساليب التربوية والمناهج العلمية؛ لننتج مشروع متكامل يساهم في تطوير العملية التعليمية. يعمل على هذا الجانب فريق متخصص في التكنولوجيا التعليمية، بالإضافة إلى متخصصين في المجال التربوي والأكاديمي؛ نعي أن التخصص هو مفتاح التميز؛ لذلك نراعيه في كل مراحل العمل.."
                    },
                    {
                        "headline": "المشاريع البرمجية",
                        "description": "ننفذ للعملاء الكرام باقة شاملة من المشاريع البرمجية باحترافٍ ودقةٍ عالية، وذلك من خلال تغطية المحاور التالية (تصميم المواقع والمنصات اﻹلكترونية - برمجة تطبيقات الهواتف - برمجة اﻷنظمة)"
                    }
                ]
            },
            "actions": {
                "contact": "تواصل معنا",
                "about": "معرفة المزيد عنّا",
                "products": "مشاريعنا"
            }
        },
        "timeline": [
            {
                "title": "نقطة الانطلاق",
                "content": "انطلقت مسيرتنا في يوم 27 من شهر أكتوبر عام 2021، ووضعنا أمامنا مجموعة من الأهداف، نعمل على تحقيقها؛ ومنها أن نكون جهة رائدة في مجال التقنية. كما إننا أردنا أن نسد ثغرة مهمة وهي توظيف التقنية في خدمة التعليم الإلكتروني، وعملنا على عددٍ من المشروعات تتعلق بهذا الهدف. وحاليًا ما زلنا نبتكر ونخطط من أجل مستقبلٍ أفضل. نعمل على جعل الواقع أفضل؛ حيث نؤمن أن العلم مفتاح تقدم الإنسان وراحته؛ لذا وظفنا خبرتنا في مجال التقنية في إيجاد حلول عملية ومبتكرة تخدم الفرد والمجتمع."
            }
        ],
        
        "contact": {
            "subheader": "أو تواصل معنا عبر الوسائل اﻵتية:",
            "email": "البريد اﻹلكتروني",
            "phone": "رقم الهاتف",
            "website": "موقع الويب"
        }
    },
    "en": {
        "sections": {
            "1": {
                "title": "Software Development Company",
                "subtitle": "We aim to devise innovative ideas that turn our clients’ ideas into reality and saves their time, effort, and cost. We possess the practical tools that make us the perfect choice for many establishments and we always strive to provide the needed support in the technical field for establishments, companies, and individuals."
            },
            "about": {
                "title": "About Us",
                "subtitle": "Who we are and What are our goals.",
                "row1": {
                    "title": "About Zamzam",
                    "content": "Zamzam is specialized in technical services and provide software solutions that support in return business development. We employ our expertise in developing and designing innovative software and systems. We offer a range of interrelated services that include e-learning, the development of educational technology and the implementation of technical projects with innovative ideas. We work with a clear vision and seek to be a contributor to the reliance on technology for the development of different sectors. We are working to be a participant in the current digital transformation of the world by providing our services on a large scale both inside and outside the Arab world. Zamzam, we craft the future with innovative ideas."
                },
                "row2": {
                    "title": "Custom softwares and technology!",
                    "content": "We set certain goals and projects in our mind by focusing our effort on developing and designing creative software systems that support many fields. In the meantime, we utilize our ideas in the educational sector in specific and that’s for its positive influence on both long and short term. We employ our experience to serve you and deliver you satisfactory results beyond your expectations."
                }
            },
            "contact": {
                "subtitle": "We are honoured to receive your inquires"
            },
            "timeline": {
                "title": "Timeline of our history",
                "subtitle": "the most important dates and events in ZamZam's history"
            },
            "products": {
                "title": "Our Projects",
                "subtitle": "Platforms, Services and Solutions that are developed and managed by ZamZam"
            },
            "industries": {
                "title": "Our Services",
                "subtitle": "Industries that we are currently working on developing it and providing software solutions to solve their problems.",
                "cols": [
                    {
                        "headline": "E-Learning",
                        "description": "We are designing platforms for the development of e-learning. There is an urgent need for more effective ideas. A team of developers is working to implement software projects professionally to serve this field. Since that we are well aware of the value of education in building leading societies, we recognize that the development of education is the main core of the development of nations, and it is the real beginning towards"
                    },
                    {
                        "headline": "Educational Technology",
                        "description": "We work on innovate an interactional educational environment that combines between technology, educational means, and scientific syllables. We work on creating an interactive learning environment combining technology, educational methods and scientific curricula to produce an integrated project that contributes to the development of the educational process. A team specialized in educational technology, as well as educators and academics, is working on this aspect; Since that specialization is the key to excellence, we put it in consideration at all stages of work."
                    },
                    {
                        "headline": "Software Projects",
                        "description": "We implement a comprehensive set of highly professional and accurate software projects for clients by covering the following aspects (Websites, Web Apps, Mobile Applications and Systems)"
                    }
                ]
            },
            "actions": {
                "contact": "Contact Us",
                "about": "More About Us",
                "products": "Our Projects"
            }
        },
        "timeline": [
            {
                "title": "Starting",
                "content": "We have set off in our path towards success in October 17th, 2021. We have set before our eyes some goals which we aim to achieve, the most important of which is to be a pioneering figure in the technical world. We also wanted to bridge the gap concerning employing technology to serve e-learning. In fact, we have worked on many projects related to this goal. We are still innovating and devising plans for a better future. We aspire to make our reality better as we believe that knowledge and education are the keys and cores of man’s success. Therefore, we utilize our efforts in this vital field in order to find practical and innovative solutions that serve both individuals and society. Soon We work with a clear vision and seek to achieve it in the near future and that's by being a contributor to the process of digital transformation; Not just locally, but globally. At present, we are developing the tools and means to help us achieve this, and we know very well that today's achievements were the goals of the past, as well as the aspirations of the present, will soon be a reality."
            }
        ],
        "contact": {
            "subheader": "or contact us via:",
            "email": "Email Address",
            "phone": "Phone",
            "website": "Website"
        }
    }
}
</i18n>
<template>
    <div class="page page-sections">
        <section id="headerSection" data-section="home" class="header-section dark-gradient fullpage-section">
            <animated-background v-if="false" />
            <v-container v-if="!loading" class="fill-height pt-12" :class="{'align-center': phoneOnly}">
                <v-slide-y-transition appear >
                <h1 class="strong-text--text">
                    <!-- <animate-text :duration="1500" :stop-after="0" :delay="500" :text="$t('sections.1.title')" /> -->
                    <div data-aos="fade-down" data-aos-duration="900">{{$t('sections.1.title')}}</div>
                </h1>
                </v-slide-y-transition>
                <v-slide-x-transition appear>
                <h3 class="text--text mt-5" style="font-weight: 400; font-size: 1.7rem; line-height: 1.7">
                    <!-- <animate-text @done="showSection1Actions = true" :duration="2500" :delay="2000" :text="$t('sections.1.subtitle')"></animate-text> -->
                    <div data-aos="fade-down" data-aos-duration="700">{{$t('sections.1.subtitle')}}</div>
                </h3>
                </v-slide-x-transition>
                <div class="actions" data-aos="fade-down" data-aos-duration="400">
                    <!-- <v-btn @click="navigateToLink('#productsSection')" color="strong-text" :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.products')}}</v-btn> -->
                    <v-btn @click="navigateToLink('#aboutSection')" color="strong-text" :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.about')}}</v-btn>
                    <v-btn @click="navigateToLink('#contactSection')" color="secondary" :large="pcOnly" outlined>{{$t('sections.actions.contact')}}</v-btn>
                </div>
            </v-container>

            <div class="section-scroll-icon">
                <scroll-icon @click="scrollToSection(2)"/>
            </div>
        </section>
        <e-section id="aboutSection" data-section="about" :headline="$t('sections.about.title')" :subtitle="$t('sections.about.subtitle')" :cols="section2Columns" light divider>
            <v-row>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'" data-aos-duration="800" :cols="pcOnly ? 5 : 12" class="">
                    <v-img src="/img/vector2.jpg" contain width="100%" max-height="320"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'" data-aos-duration="800" class="mt-6 mt-lg-0" :cols="pcOnly ? 6 : 12">
                    <h1 class="headline">{{$t('sections.about.row1.title')}}</h1>
                    <p class="medium-text text-light--text mt-6 mt-lg-8" :class="{'text-center': phoneOnly}">
                        {{$t('sections.about.row1.content')}}
                    </p>
                    <div class="actions">
                        <v-btn @click="navigateToLink('#industriesSection')" large depressed color="primary">{{$t('sections.industries.title')}}</v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-top: 3.5rem" class=" flex-row-reverse">

                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'" data-aos-duration="800" :cols="pcOnly ? 5 : 12" class="">
                    <v-img src="/img/vector1.jpg" contain width="100%" max-height="320"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'" data-aos-duration="800" class="mt-6 mt-lg-0" :cols="pcOnly ? 6 : 12">
                    <h1 class="headline">{{$t('sections.about.row2.title')}}</h1>
                    <p class="medium-text text-light--text mt-6 mt-lg-8" :class="{'text-center': phoneOnly}">
                        {{$t('sections.about.row2.content')}}
                    </p>

                    <div class="actions">
                        <v-btn @click="navigateToLink('#productsSection')" large depressed color="primary">{{$t('sections.products.title')}}</v-btn>
                        <v-btn @click="navigateToLink('#timelineSection')" large depressed color="primary" outlined class="ms-lg-4 mt-3 mt-lg-0">{{$t('sections.timeline.title')}}</v-btn>
                    </div>
                </v-col>
            </v-row>
        </e-section>

        <cols-section id="industriesSection" :headline="$t('sections.industries.title')" :subtitle="$t('sections.industries.subtitle')" :cols="industriesCols">

        </cols-section>


        <e-section id="productsSection" data-section="products" background="light" :headline="$t('sections.products.title')" :subtitle="$t('sections.products.subtitle')" light>
            <v-row class="flex-wrap justify-center">
                <v-col v-for="(project, i) in projects" :key="i" :cols="pcOnly ? 4 : 12">
                    <v-card v-ripple="project.href ? {class: 'soft-ripple'} : false" data-aos="fade-up" :data-aos-duration="800" :data-aos-delay="400*i" class="card-shadow fill-height" :href="project.href ? project.href : ''" :target="project.href ? '_blank' : ''">
                        <v-img :src="project.image" cover height="250" />
                        <!-- <div class="py-1 px-3">
                            <v-card-title class="primary--text mb-1">{{project.title}}</v-card-title>
                            <v-card-subtitle>{{project.subtitle}}</v-card-subtitle>
                        </div> -->
                    </v-card>
                </v-col>
            </v-row>
        </e-section>

        <e-section id="timelineSection" data-section="timeline" divider :headline="$t('sections.timeline.title')" :subtitle="$t('sections.timeline.subtitle')" light>
            <timeline data-aos="fade-up" :data-aos-duration="800" :data="timeline" />
        </e-section>

        <e-section id="contactSection" data-section="contact" :headline="$t('links.contact')" :subtitle="$t('sections.contact.subtitle')" light>
            <v-row class="justify-center px-lg-12">
                <v-col :cols="pcOnly ? 6 : 12" class="ps-lg-12">
                    <contact-form data-once :data-aos="'fade'" :data-aos-duration="800" />
                </v-col>
                <v-col v-if="pcOnly" cols="1" class="text-center">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col :cols="pcOnly ? 5 : 12">
                    <div :data-aos="'fade'" :data-aos-duration="800">
                        <v-subheader>
                            {{$t('contact.subheader')}}
                        </v-subheader>
                        <v-list two-line>
                            <v-list-item v-for="item in contactItems" :key="item.name" :target="item.href ? '_blank' : ''" :href="item.href ? item.href : (item.name === 'email' ? `mailto:${item.value}` : '')">
                                <v-list-item-icon>
                                    <v-icon :color="item.color">{{item.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="mb-2">{{$t('contact.' + item.name)}}</v-list-item-subtitle>
                                    <v-list-item-title>{{item.value}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                    </div>
                </v-col>
            </v-row>
        </e-section>

        <e-footer />

        <scroll-to-top />
    </div>
</template>

<script>
// @ is an alias to /src
import 'particles.js';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


import ScrollToTop from '@/components/custom/scroll-to-top'
// import AnimateText from '@/components/custom/animate-text'
import ScrollIcon from '@/components/custom/scroll-icon'
import ContactForm from '@/components/custom/contact-form'
import AnimatedBackground from '@/components/custom/animated-background'
import ColsSection from '@/components/default/cols-section'
import ESection from '@/components/default/e-section'
import Timeline from '@/components/default/timeline'
import EFooter from '@/components/main/e-footer'


import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: "Home",
    components: {
        ScrollToTop,
        ContactForm,
        // AnimateText,
        Timeline,
        ColsSection,
        ESection,
        ScrollIcon,
        EFooter,
        AnimatedBackground
    },
    data(){
        return {
            showSection1Actions: false,
            currentScrollTop: 0,
            
            contactItems: [
                {name: 'phone', icon: 'mdi-phone', value: '+1(832)5120847', color: 'blue', href: 'tel:+1-832-512-0847'},
                {name: 'email', icon: 'mdi-email', value: 'info@zamzam-gs.com', color: 'primary'},
                {name: 'website', icon: 'mdi-web', value: 'www.zamzam-gs.com', color: 'grey', href: 'https://zamzam-gs.com'},
            ],
            projects: [
                {
                    image: '/img/iq-academy2.png',
                    href: 'https://iq-academy.com'
                },
                {
                    image: '/img/babel.png',
                    href: 'https://apps.apple.com/app/id1580908577'
                },
                {
                    image: '/img/smartacademy.png',
                    href: 'https://apps.apple.com/app/id1589468298',
                },
            ],

            section2Columns: [
                {
                    headline: 'العنوان اﻷول',
                    description: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها',
                    color: 'success',
                    icon: 'mdi-cart'
                },
                {
                    headline: 'العنوان الثاني',
                    description: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها',
                    color: 'blue',
                    icon: 'mdi-cube-send'
                },
                {
                    headline: 'العنوان الثالث',
                    description: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها',
                    color: 'deep-orange',
                    icon: 'mdi-cash-multiple'
                }
            ]
        }
    },
    computed: {
        industriesCols(){
            return [
               {color: 'blue', icon: 'mdi-cart-outline', headline: this.$t('sections.industries.cols.0.headline'), description: this.$t('sections.industries.cols.0.description')},
               {color: 'deep-orange', icon: 'mdi-cart-outline', headline: this.$t('sections.industries.cols.1.headline'), description: this.$t('sections.industries.cols.1.description')},
               {color: 'teal', icon: 'mdi-store', headline: this.$t('sections.industries.cols.2.headline'), description: this.$t('sections.industries.cols.2.description')},
            ];
        },
        timeline(){

            return [
                {date: '30/09/2019', title: this.$t('timeline.0.title'), content: this.$t('timeline.0.content')},
            ];
        },
        ...GlobalComputed
    },
    methods: {
        scrollToSection(n){
            let i = n-1,
                element = document.querySelectorAll('.page-sections section')[i];
            if (element){
                this.scrollToElement(element);
            }
        },
        scrollObserver(){
            window.addEventListener('scroll', ()=>{
                this.currentScrollTop = document.documentElement.scrollTop;
            })
        },
        handleScrollChange(scrollTop){
            const sections = document.querySelectorAll('.page-sections section');
            sections.forEach(section => {
                let offsetTop = section.offsetTop - this.navbarHeight, // decrease navbarHeight
                    offsetBottom = offsetTop + section.offsetHeight;

                if (scrollTop >= offsetTop && scrollTop < offsetBottom){
                    let sectionName = section.getAttribute('data-section');
                    if(sectionName){
                        this.$store.commit('SET_ACTIVE_SECTION', sectionName)
                    }
                    // section is visible...
                    if (section.getAttribute('data-theme') === 'light'){
                        
                        if (!this.lightNavbar){
                            this.$store.commit('SET_LIGHT_NAVBAR', true);
                        }
                    }else{
                        if (this.lightNavbar){
                            this.$store.commit('SET_LIGHT_NAVBAR', false);
                        }
                    }
                }
            })

        },

        ...GlobalMethods
    },
    watch: {
        currentScrollTop(top){
            this.handleScrollChange(top);
        }
    },

    mounted(){
        this.scrollObserver();
        AOS.init({container: document.documentElement, once: true, easing: 'ease'});
    }
};
</script>
<style lang="scss" scoped>
    section{

        .container{
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;

            &, *{
                z-index: 1;
            }
        }
        &.header-section{
            color: white;
            background-image: url('/img/background.jpg')!important;
            background-size: cover!important;
            background-position: center!important;
            background-blend-mode: overlay;
            background-color: #031c4a!important;
        }
        h1{
            font-size: 4rem;
            font-weight: 300;

            @media(max-width: 960px){
                text-align: center;
                font-size: 1.7rem;
                line-height: 2.4rem;
                font-weight: 400;
            }
        }
        h2{
            font-size: 2.75rem;
            word-break: break-word!important;
            font-weight: 200;

            @media(max-width: 960px){
                text-align: center;
                font-size: 1.3rem;
                font-weight: 300;
            }
        }
        .actions{
            margin-top: 3rem;
            @media(max-width: 960px){
                margin: 2rem 0;
                width: 100%;
                text-align: center;
                justify-content: center;
            }
            .v-btn{
                @media(min-width: 960px){
                    min-width: 130px;
                    min-height: 45px;
                }
            }
        }
        .section-scroll-icon{
            z-index: 2;
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>